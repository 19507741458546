import React, { useEffect, useRef, useState } from "react";

import { Popper } from "@mui/base";
import { Grow, Stack } from "@mui/material";
import { BiLogoInstagram, BiLogoLinkedin } from "react-icons/bi";
import { Link } from "react-router-dom";
import "./SocialBar.sass";
const SocialBar = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const defaultIcon = {
    icon: <BiLogoLinkedin />,
    path: "https://www.linkedin.com/in/tu-tran-161554289",
  };
  const socialList = [
    {
      icon: <BiLogoInstagram />,
      path: "https://www.instagram.com/tu.tranc?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
    },
  ];

  const LinkToTab = (props) => {
    const { icon, path } = props;
    return (
      <Link to={path} target="_blank" rel="noopener noreferrer">
        {icon}
      </Link>
    );
  };

  const handleToggle = () => {
    setOpen(true);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <aside
      className="social-bar"
      onMouseLeave={handleClose}
      onMouseEnter={handleToggle}
    >
      <div>
        <button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          className="custom-btn"
        >
          <LinkToTab {...defaultIcon} />
        </button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Stack className="social-bar-list">
                {socialList.map((item, index) => {
                  return <LinkToTab key={index} {...item} />;
                })}
              </Stack>
            </Grow>
          )}
        </Popper>
      </div>
    </aside>
  );
};

export default SocialBar;
