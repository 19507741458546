import React from "react";
import INTERIOR from "../../../assets/images/home_about/interiors.png";
import ARCHITECTURE from "../../../assets/images/home_about/architecture.png";
import PLANNING from "../../../assets/images/home_about/planing.png";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import avata from "../../../assets/images/home_about/avata.jpg";
import "./About.sass";
import { Grid, Stack } from "@mui/material";

const About = () => {
  const specializations = [
    {
      title: "INTERIOR",
      icon: INTERIOR,
    },
    {
      title: "ARCHITECTURE",
      icon: ARCHITECTURE,
    },
    {
      title: "PLANNING",
      icon: PLANNING,
    },
  ];
  const AboutBackground = () => {
    return <div className="about-background">About</div>;
  };
  return (
    <>
      <SectionTitle mainTitle="About" subTitle="BES" />
      <section className="about">
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "auto !important"
          }}
          maxWidth={"xl"}
          spacing={{ sx: 0, md: 3 }}
        >
          <Grid item xs={8} md={7} sx={{ padding: 0 }}>
            <Grid container sx={{ margin: "auto" }}>
              <Grid item sm={12} md={4} className="about-highlight">
                <h3>we turn ideas into works of art.</h3>
                <p>
                  BES Studio is an interior design studio specializing in
                  transforming spaces. From interior recreation to renovation,
                  we're dedicated to crafting captivating environments. At BES
                  Studio, we believe in the power of visualization. Using
                  advanced 3D rendering, we let clients step into the future of
                  their spaces, turning ideas into tangible designs. Join us on
                  our journey of design and innovation. From interiors that tell
                  stories to spaces that inspire, BES Studio is here to shape
                  your vision.
                </p>
                <AboutBackground />
              </Grid>
              <Grid item xs={12} md={4} className="about-highlight">
                <h3>our specialization:</h3>
                <Stack
                  direction="column"
                  sx={{ margin: { xs: "0", md: "1rem" } }}
                >
                  {specializations.map((spec, index) => {
                    return (
                      <div className="spec" key={index}>
                        <div
                          className="spec-img"
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <img src={spec.icon} alt={spec.title} />
                        </div>
                        <h5>{spec.title}</h5>
                      </div>
                    );
                  })}
                </Stack>
              </Grid>
              <Grid item xs={12} md={4} className="about-highlight">
                <img src={avata} alt="avata" />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </section >
    </>
  );
};

export default About;
