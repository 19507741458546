import React from "react";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import Slider from "react-slick";
import { Button, Grid } from "@mui/material";
import ProjectItem from "../../../components/ProjectItem/ProjectItem";
import { useSelector } from "react-redux";

import "./Project.sass";

const Project = () => {
  const projectList = useSelector(store => store.project.projectList)
  const widthScreen = useSelector(store => store.pageControl.widthScreen)
  const PrevButton = (props) => {
    const { onClick } = props;
    return (
      <Button
        color="inherit"
        onClick={onClick}
        style={{
          position: "absolute",
          top: "50%",
          left: "2%",
          zIndex: "99",
          transform: "translate(0, -50%)",
          letterSpacing: "0.1rem",
        }}
      >
        <BsArrowLeft style={{ marginRight: "0.5rem" }} />
      </Button>
    );
  };

  const NextButton = (props) => {
    const { onClick } = props;
    return (
      <Button
        color="inherit"
        onClick={onClick}
        style={{
          position: "absolute",
          top: "50%",
          right: "2%",
          zIndex: "99",
          transform: "translate(0, -50%)",
          letterSpacing: "0.1rem",
        }}
      >
        <BsArrowRight style={{ marginLeft: "0.5rem" }} />
      </Button>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
    adaptiveHeight: true,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnFocus: true,
  }

  return (
    <div className="home-project">
      <SectionTitle mainTitle="our" subTitle="projects" />
      <Grid container maxWidth="xl" m={"auto"}>
        <Grid item xs={12}>
          {
            widthScreen !== null &&
            <>
              {
                widthScreen < 600 ? <Slider className="project-carousel" {...settings} slidesToShow={2}>
                  {projectList.map((item, index) => {
                    return <ProjectItem key={index} item={item} />;
                  })}
                </Slider> : <Slider className="project-carousel" {...settings} slidesToShow={3}>
                  {projectList.map((item, index) => {
                    return <ProjectItem key={index} item={item} />;
                  })}
                </Slider>

              }

            </>
          }
        </Grid>
      </Grid>
    </div>
  );
};
export default Project;
